import { NOTIFY_ERROR, NOTIFY_SUCCESS, CLOSE_ALERT } from './actions';

let id = 0;

export default {
    [NOTIFY_SUCCESS]: (state, payload) => {
        state.alerts.push({
            id: ++id,
            type: 'success',
            message: payload,
        });
    },

    [NOTIFY_ERROR]: (state, payload) => {
        state.alerts.push({
            id: ++id,
            type: 'danger',
            message: payload,
        });
    },

    [CLOSE_ALERT]: (state, payload) => {
        state.alerts = state.alerts.filter(alert => alert.id !== payload);
    },
};
