<template>
    <div
        class="icon"
        v-html="require(`!html-loader!@/assets/svg/${this.name}.svg`)"
    />
</template>

<script>
export default {
    props: {
        /**
         * Icon SVG file name
         */
        name: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.icon {
    @apply flex items-center justify-center;
}
</style>
