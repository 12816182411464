import {
    SET_DOCUMENT_INSTANCE,
    SET_APPLICATION,
    SET_GUARANTOR,
    SET_BACKGROUND_SCREENING,
    SET_IDENTITY_VERIFICATION,
    SET_APPLICATION_ID,
    SET_BM_CREDENTIALS,
    ClEAR_STATE,
} from './mutations';

const setDocumentInstance = ({ commit }, payload) => commit(SET_DOCUMENT_INSTANCE, payload);
const setApplication = ({ commit }, payload) => commit(SET_APPLICATION, payload);
const setGuarantor = ({ commit }, payload) => commit(SET_GUARANTOR, payload);
const setBackgroundScreening = ({ commit }, payload) => commit(SET_BACKGROUND_SCREENING, payload);
const setIdentityVerification = ({ commit }, payload) => commit(SET_IDENTITY_VERIFICATION, payload);
const setApplicationId = ({ commit }, payload) => commit(SET_APPLICATION_ID, payload);
const setbluemoon_credentials = ({ commit }, payload) => commit(SET_BM_CREDENTIALS, payload);
const clearState = ({ commit }, payload) => commit(ClEAR_STATE, payload);

export default {
    setDocumentInstance,
    setApplication,
    setGuarantor,
    setBackgroundScreening,
    setIdentityVerification,
    setApplicationId,
    setbluemoon_credentials,
    clearState,
};
