import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

// Create vuex persist object
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

import alerts from '@/store/alerts';
import pass from './pass';

// Create the store
export default createStore({
    modules: {
        alerts,
        pass,
    },
    plugins: [vuexLocal.plugin],
});
