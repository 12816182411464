import { createI18n } from 'vue-i18n';
import EventBus from '@/utils/EventBus';
import store from './store';

export const i18n = createI18n({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    globalInjection: true,
});

export const setLocale = async locale => {
    const messages = await import(`@/locales/${locale}`);
    i18n.global.missingWarn = true;
    i18n.global.setLocaleMessage(locale, messages.default);
    i18n.global.locale.value = locale;

    const pluralRules = new Intl.PluralRules(locale);
    const { pluralCategories } = pluralRules.resolvedOptions();
    i18n.global.pluralRules[locale] = value => pluralCategories.indexOf(pluralRules.select(value));

    EventBus.emit('locale-changed', locale);
};

setLocale(store.state.settings.locale);

store.watch(
    state => state.settings.locale,
    locale => {
        setLocale(locale);
    }
);
