import { createApp } from 'vue';
import { configureCompat } from '@vue/compat';

configureCompat({
    INSTANCE_LISTENERS: false,
    INSTANCE_SCOPED_SLOTS: false,
    RENDER_FUNCTION: false,
    COMPONENT_V_MODEL: false,
});

import PassDataProvider from './api/pass';

import { i18n } from '@/i18n';

// Vue router setup
import router from './router';

// Vuex store setup
import store from './store';

// Tailwind setup
import '@/assets/styles.css';

import App from './App';

const app = createApp(App);

app.use(router);

app.use(store);

app.use(i18n);

// PASS API data provider
app.use(PassDataProvider, { name: 'passDataProvider' });

app.mount('#app');
