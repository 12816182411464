const hasPermission = (state, getters, rootState, rootGetters) => permissionIds => {
    const permissions = rootGetters['auth/appsPermissions']['RI'];

    if (!permissions) {
        return false;
    }

    const permissionSet = new Set(permissions);

    return (Array.isArray(permissionIds) ? permissionIds : [permissionIds]).some(id => permissionSet.has(id));
};

const reservationChanged = state => state.reservationChanged;

const createdReservationsCount = state => state.createdReservationsCount;

export default {
    hasPermission,
    reservationChanged,
    createdReservationsCount,
};
