<template>
    <div class="alerts">
        <transition-group
            appear
            name="alert"
        >
            <MessageBox
                v-for="alert of alerts"
                :key="alert.id"
                class="mx-auto mt-2 max-w-sm"
                :theme="alert.type"
                rounded
                closable
                @close="closeAlert(alert.id)"
            >
                <div v-html="alert.message"></div>
            </MessageBox>
        </transition-group>
    </div>
</template>

<script>
import MessageBox from '@/components/ui/MessageBox';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: { MessageBox },

    computed: {
        ...mapGetters({
            alerts: 'alerts/getAlerts',
        }),
    },

    methods: {
        ...mapActions({
            closeAlert: 'alerts/closeAlert',
        }),
    },
};
</script>

<style scoped>
.alerts {
    @apply fixed bottom-20 md:bottom-4 px-5 z-50;

    left: 50%;
    transform: translate(-50%, 0);
}

.alert-enter-active,
.alert-leave-active,
.alert-move {
    transition: all 0.5s ease;
}

.alert-enter-from,
.alert-leave-to {
    opacity: 0;
}
</style>
