import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

// Create vuex persist object
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ['auth', 'settings', 'application'],
});

import alerts from '@/store/alerts';
import auth from '@/store/auth';
import confirm from '@/store/confirm';
import settings from '@/store/settings';
import msgs from '@/store/msgs';
import ri from '@/store/ri';
import accounting from '@/store/accounting';
import application from '@/store/applicationService';

// Create the store
export default createStore({
    modules: {
        auth,
        alerts,
        accounting,
        confirm,
        settings,
        msgs,
        ri,
        application,
    },
    plugins: [vuexLocal.plugin],
});
