<template>
    <div class="content-area">
        <slot name="header"></slot>
        <div
            ref="pageContent"
            class="content-area__body"
            :class="contentClass"
        >
            <transition
                v-if="watchForScroll"
                name="fade"
            >
                <div
                    v-if="loading && reachedTop"
                    class="text-center"
                >
                    {{ $t('app.content_loading') }}
                </div>
            </transition>
            <slot name="default"></slot>
            <transition
                v-if="watchForScroll"
                name="fade"
            >
                <div
                    v-if="loading && reachedBottom"
                    class="text-center"
                >
                    {{ $t('app.content_loading') }}
                </div>
            </transition>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    props: {
        contentClass: {
            type: String,
            required: false,
            default: 'page-paddings',
        },

        watchForScroll: {
            type: Boolean,
            default: false,
        },

        scrollTopThreshold: {
            type: Number,
            default: 0,
        },

        scrollBottomThreshold: {
            type: Number,
            default: 0,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['reachedTop', 'reachedBottom', 'scroll'],

    data() {
        return {
            reachedTop: true,
            reachedBottom: false,
        };
    },

    mounted() {
        if (this.watchForScroll) {
            this.$refs.pageContent.addEventListener('scroll', this.handleScroll);
        }
    },

    beforeUnmount() {
        if (this.watchForScroll) {
            this.$refs.pageContent.removeEventListener('scroll', this.handleScroll);
        }
    },

    methods: {
        handleScroll(event) {
            if (this.loading) return;

            this.$emit('scroll', event);

            const topReachedNow = event.target.scrollTop <= this.scrollTopThreshold;
            const bottomReachedNow =
                event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <= this.scrollBottomThreshold;

            if (topReachedNow && !this.reachedTop) {
                this.reachedTop = true;
                this.$emit('reachedTop');
            } else if (!topReachedNow && this.reachedTop) {
                this.reachedTop = false;
            }

            if (bottomReachedNow && !this.reachedBottom) {
                this.reachedBottom = true;
                this.$emit('reachedBottom');
            } else if (!bottomReachedNow && this.reachedBottom) {
                this.reachedBottom = false;
            }
        },
    },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
    height: 0;
    opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: height 0.5s, opacity 0.3s;
}
.fade-enter-to,
.fade-leave-from {
    @apply h-6;
}
</style>
