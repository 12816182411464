import { CLEAR_STATE, SET_TOTALS, SET_CHARGE_TOTALS } from './mutations';

const clearState = ({ commit }) => commit(CLEAR_STATE);

const setTotals = ({ commit }, payload) => commit(SET_TOTALS, payload);

const setChargeTotals = ({ commit }, payload) => commit(SET_CHARGE_TOTALS, payload);

export default {
    clearState,
    setTotals,
    setChargeTotals,
};
