const ADD_CONFIRMATION = (state, payload) => {
    state.dialogs.push(payload);
};

const REMOVE_LAST_CONFIRMATION = state => {
    state.dialogs.pop();
};

export default {
    ADD_CONFIRMATION,
    REMOVE_LAST_CONFIRMATION,
};
