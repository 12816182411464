import Axios from 'axios';
import { stringify } from 'query-string';
import { pickBy } from 'lodash-es';

const httpClient = baseURL => request =>
    Axios.request({
        ...request,
        baseURL,
        paramsSerializer: params => stringify(params),
        headers: pickBy({
            ...request.headers,
        }),
    }).catch(error => {
        const response = error?.response;
        const message = response?.data?.message || error.message;

        return Promise.reject({
            message,
            ...response,
        });
    });

export default httpClient;
