export const SET_RESERVATION_CHANGED = 'SET_RESERVATION_CHANGED';
export const SET_RESERVATION_CREATED = 'SET_RESERVATION_CREATED';

const setReservationChanged = ({ commit }, payload) => {
    commit(SET_RESERVATION_CHANGED, payload);
};

const setCreatedReservationsCount = ({ commit }, payload) => {
    commit(SET_RESERVATION_CREATED, payload);
};

export default {
    setReservationChanged,
    setCreatedReservationsCount,
};
