import {
    SIGN_IN,
    SIGN_OUT,
    SET_PROFILES,
    SET_CURRENT_PROFILE,
    SET_APP_PERMISSIONS,
    CLEAR_APP_PERMISSIONS,
    SET_CURRENT_USER,
    SET_CURRENT_COMMUNITY,
} from './mutations';

const signIn = ({ commit }, payload) => commit(SIGN_IN, payload);

const signOut = ({ commit }) => commit(SIGN_OUT);

const setProfiles = ({ commit }, payload) => commit(SET_PROFILES, payload);

const setCurrentProfile = ({ commit }, payload) => commit(SET_CURRENT_PROFILE, payload);

const setAppPermissions = ({ commit }, payload) => commit(SET_APP_PERMISSIONS, payload);

const clearAppPermissions = ({ commit }, payload) => commit(CLEAR_APP_PERMISSIONS, payload);

const setCurrentUser = ({ commit }, payload) => commit(SET_CURRENT_USER, payload);

const setCurrentCommunity = ({ commit }, payload) => commit(SET_CURRENT_COMMUNITY, payload);

export default {
    signIn,
    signOut,
    setProfiles,
    setCurrentProfile,
    setAppPermissions,
    clearAppPermissions,
    setCurrentUser,
    setCurrentCommunity,
};
