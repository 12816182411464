import { SET_RESERVATION_CHANGED, SET_RESERVATION_CREATED } from './actions';

export default {
    [SET_RESERVATION_CHANGED]: (state, payload) => {
        state.reservationChanged = payload;
    },
    [SET_RESERVATION_CREATED]: (state, payload) => {
        state.createdReservationsCount = payload;
    },
};
