export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE';
export const SET_APP_PERMISSIONS = 'SET_APP_PERMISSIONS';
export const CLEAR_APP_PERMISSIONS = 'CLEAR_APP_PERMISSIONS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_COMMUNITY = 'SET_CURRENT_COMMUNITY';

export default {
    [SIGN_IN]: (state, payload) => {
        state.user = payload;
        state.authenticated = true;
    },
    [SIGN_OUT]: state => {
        state.user = null;
        state.authenticated = false;
        state.appsPermissions = {};
        // The system should clean these values too in order to prevent issues by loading other user's info
        state.profiles = [];
        state.currentProfile = null;
        state.currentUser = null;
    },
    [SET_PROFILES]: (state, payload) => {
        state.profiles = payload.flatMap(contact =>
            contact.customers.flatMap(customer => {
                let userType = '';
                return customer.communities.map(community => ({
                    communityName: community.communityName,
                    profiles: community.relationshipTypes
                        .flatMap(relationship => {
                            userType = relationship.type;
                            return relationship.profiles;
                        })
                        .map(profile => ({
                            ...profile,
                            type: userType,
                            contactId: contact.contactId,
                            customerId: customer.customerId,
                            customerName: customer.customerName,
                            communityId: community.communityId,
                            communityName: community.communityName,
                            communityTimezone: community.communityTimezoneId,
                        })),
                }));
            })
        );

        if (
            state.currentProfile &&
            !state.profiles
                .flatMap(community => community.profiles || [])
                .find(profile => profile.profileId === state.currentProfile.profileId)
        ) {
            state.currentProfile = null;
        }
    },
    [SET_CURRENT_PROFILE]: (state, payload) => {
        state.currentProfile = payload;
    },
    [SET_CURRENT_USER]: (state, payload) => {
        state.currentUser = payload;
    },
    [SET_CURRENT_COMMUNITY]: (state, payload) => {
        state.currentCommunity = payload;
    },
    [SET_APP_PERMISSIONS]: (state, { appCode, permissions }) => {
        state.appsPermissions[appCode] = permissions;
    },
    [CLEAR_APP_PERMISSIONS]: (state, appCode) => {
        state.appsPermissions[appCode] = undefined;
    },
};
