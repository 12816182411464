export const SET_PASS_DATA = 'SET_PASS_DATA';
export const SET_CURRENT_TOKEN = 'SET_CURRENT_TOKEN';
export const SET_CURRENT_PASS_OTP_PHONE = 'SET_CURRENT_PASS_OTP_PHONE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const CLEAR_CURRENT_TOKEN = 'CLEAR_CURRENT_TOKEN';

const setPassData = ({ commit }, payload) => {
    commit(SET_PASS_DATA, payload);
};

const setCurrentToken = ({ commit }, payload) => {
    commit(SET_CURRENT_TOKEN, payload);
};

const setAccessToken = ({ commit }, payload) => {
    commit(SET_ACCESS_TOKEN, payload);
};

const setCurrentPassOtpPhone = ({ commit }, payload) => {
    commit(SET_CURRENT_PASS_OTP_PHONE, payload);
};

const clearCurrentToken = ({ commit }, payload) => {
    commit(CLEAR_CURRENT_TOKEN, payload);
};

export default {
    setPassData,
    setCurrentToken,
    setAccessToken,
    setCurrentPassOtpPhone,
    clearCurrentToken,
};
