const routes = [
    {
        name: 'pass.details',
        path: '/:id',
        component: () => {
            return import('../views/pass/Details');
        },
        meta: {
            theme: 'payments',
            title: 'pass details',
        },
    },
];

export default routes;
