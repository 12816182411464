import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '../httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '../../store';

const baseURL = getEnvVariable('VUE_APP_C2C_API_URL');
const actionsWithAccessToken = ['start'];

const beforeRequestInterceptor = ({ requestConfig, action }) => {
    if (action !== 'startWebSession') {
        const headers = {
            ...requestConfig.headers,
            'X-Pass-Session': store.getters['pass/currentToken'],
        };

        if (actionsWithAccessToken.includes(action)) {
            headers['X-Access-Token'] = store.getters['pass/accessToken'];
        }

        return {
            ...requestConfig,
            headers,
        };
    }

    return requestConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, { beforeRequestInterceptor });

export default new DataProvider(httpAdapter);
