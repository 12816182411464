<template>
    <div id="app">
        <div class="app-container">
            <ContentArea class="w-full">
                <template v-slot:default>
                    <router-view></router-view>
                </template>
            </ContentArea>
        </div>
        <Alerts />
    </div>
</template>

<script>
import Alerts from '@/components/root/Alerts';
import ContentArea from '@/components/ui/ContentArea';

export default {
    components: {
        ContentArea,
        Alerts,
    },
};
</script>

<style>
#app {
    @apply h-full;
}

.app-container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    height: 100%;
}
</style>
