const authenticated = state => state.authenticated;
const cognitoUserId = state => state.user.username;
const profiles = state => state.profiles;
const currentProfile = state => state.currentProfile;
const appsPermissions = state => state.appsPermissions;
const currentUser = state => state.currentUser;
const currentCommunity = state => state.currentCommunity;

export default {
    authenticated,
    cognitoUserId,
    profiles,
    currentProfile,
    appsPermissions,
    currentUser,
    currentCommunity,
};
