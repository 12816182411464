import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    authenticated: false,
    user: null,
    profiles: [],
    currentProfile: null,
    appsPermissions: {},
    currentUser: null,
    currentCommunity: null,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
