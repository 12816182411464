const getPassByUri = state => passUri => state.passes?.[passUri];
const currentToken = state => state.currentToken;
const currentTimezone = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.timezone;
const currentOtpPhone = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.otpPhone;
const passType = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.passType;
const apartmentName = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.apartmentCommunityName;
const accessToken = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.accessToken;
const propertyPhoneNumber = state => Object.values(state.passes).find(({ token }) => token === state.currentToken)?.propertyPhoneNumber;

export default {
    getPassByUri,
    currentToken,
    currentTimezone,
    currentOtpPhone,
    passType,
    apartmentName,
    accessToken,
    propertyPhoneNumber,
};
