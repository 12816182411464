export const SET_APPLICATION = 'SET_APPLICATION';
export const SET_GUARANTOR = 'SET_GUARANTOR';
export const SET_DOCUMENT_INSTANCE = 'SET_DOCUMENT_INSTANCE';
export const SET_BACKGROUND_SCREENING = 'SET_BACKGROUND_SCREENING';
export const SET_IDENTITY_VERIFICATION = 'SET_IDENTITY_VERIFICATION';
export const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
export const SET_BM_CREDENTIALS = 'SET_BM_CREDENTIALS';
export const ClEAR_STATE = 'ClEAR_STATE';

export default {
    [SET_DOCUMENT_INSTANCE]: (state, payload) => {
        state.document_instance = payload;
    },
    [SET_APPLICATION]: (state, payload) => {
        state.application = payload;
    },
    [SET_GUARANTOR]: (state, payload) => {
        state.guarantor = payload;
    },
    [SET_BACKGROUND_SCREENING]: (state, payload) => {
        state.background_screening = payload;
    },
    [SET_IDENTITY_VERIFICATION]: (state, payload) => {
        state.identity_verification = payload;
    },
    [SET_APPLICATION_ID]: (state, payload) => {
        state.currentApplicationId = payload;
    },
    [SET_BM_CREDENTIALS]: (state, payload) => {
        state.bm_credentials = payload;
    },
    [ClEAR_STATE]: (state, payload = 'all') => {
        switch (payload) {
        case 'document_instance':
            state.document_instance = null;
            break;
        case 'application':
            state.application = null;
            break;
        case 'guarantor':
            state.guarantor = null;
            break;
        case 'background_screening':
            state.background_screening = null;
            break;
        default:
            state.document_instance = null;
            state.application = null;
            state.guarantor = null;
            state.background_screening = null;
            state.identity_verification = null;
            state.currentApplicationId = null;
            break;
        }
    },
};
