const hasPermissionByRole = (state, getters, rootState, rootGetters) => role => {
    const currentProfileRole = rootGetters['auth/currentProfile']?.type;

    const hasPermission = currentProfileRole === role || role === 'applicant/guarantor';

    return hasPermission;
};

const getDocumentInstance = state => state.document_instance;
const getBackgroundScreening = state => state.background_screening;
const getApplication = state => state.application;
const getGuarantor = state => state.guarantor;
const getApplicationId = state => state.currentApplicationId;
const getbluemoon_credentials = state => state.bm_credentials;

export default {
    getDocumentInstance,
    getApplication,
    getGuarantor,
    getBackgroundScreening,
    hasPermissionByRole,
    getApplicationId,
    getbluemoon_credentials,
};
