<template>
    <div
        class="message-box"
        :class="{
            [`message-box--theme-${theme}`]: theme,
            [`message-box--size-${size}`]: size,
            [`message-box--rounded`]: rounded,
        }"
    >
        <Icon
            v-if="leadingIcon"
            :name="leadingIcon"
            class="message-box__icon"
        />

        <div class="message-box__text">
            <!-- @slot MessageBox content -->
            <slot />
        </div>

        <button
            v-if="closable"
            class="message-box__close"
            @click="$emit('close')"
        >
            <Icon
                class="w-3 h-3 min-w-3"
                name="close_2"
            />
        </button>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },
    props: {
        /**
         * Message theme: `simple | rounded`
         */
        theme: {
            type: String,
            validator: function (value) {
                return [undefined, 'danger', 'success', 'warning'].indexOf(value) !== -1;
            },
        },

        /**
         * Message size
         */
        size: {
            type: String,
            validator: function (value) {
                return ['medium', 'small'].indexOf(value) !== -1;
            },

            default: 'medium',
        },

        /**
         * Add rounded corners
         */
        rounded: {
            type: Boolean,
        },

        /**
         * Add close button
         */
        closable: {
            type: Boolean,
        },

        /**
         * Leading icon name
         */
        leadingIcon: {
            type: [String, undefined],
            required: false,
        },
    },

    emits: [
        /**
         * Emits on cancel icon click
         */
        'close',
    ],
};
</script>

<style scoped>
.message-box {
    @apply flex bg-gray-100 text-gray-700;
}

.message-box__icon {
    @apply w-4 h-4 min-w-4 mr-4 text-gray-450;
    margin-top: 0.05rem;
}

.message-box__close {
    @apply pl-4 pb-4 self-start;
}

.message-box__text {
    @apply flex-grow font-medium font-sofia tracking-wide;
    font-size: 15px;
    line-height: 18px;
}

.message-box--rounded {
    @apply border rounded-lg border-gray-150 bg-gray-100;
}

.message-box--size-medium {
    @apply p-6;
}

.message-box--size-small {
    @apply py-4 pl-4 pr-6;
}

.message-box--theme-danger {
    @apply bg-red-200 border-red-300 text-red-600;
}

.message-box--theme-danger .message-box__icon {
    @apply text-red-600;
}

.message-box--theme-success {
    @apply bg-green-200 border-green-400 text-green-700;
}

.message-box--theme-success .message-box__icon {
    @apply text-green-700;
}

.message-box--theme-warning {
    @apply bg-orange-200 border-orange-300 text-orange-600;
}

.message-box--theme-warning .message-box__icon {
    @apply text-orange-600;
}
</style>
