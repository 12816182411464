export default {
    passes: {
        startWebSession: ['POST', '/access/pass/start'],
        getOne: ['GET', '/access/pass'],
        verify: ['POST', '/access/pass/verify'],
        sendOtp: ['POST', '/access/pass/otp'],
    },
    pulse: {
        start: ['POST', '/access/pass/pulse/{id}'],
        getStatus: ['GET', '/access/pass/pulse/status'],
    },
};
