const requestConfirmation = ({ commit }, payload) => {
    const promise = new Promise(resolve => {
        commit('ADD_CONFIRMATION', {
            ...payload,
            resolve,
        });
    });

    promise.then(() => {
        commit('REMOVE_LAST_CONFIRMATION');
    });

    return promise;
};

export default {
    requestConfirmation,
};
