export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const CLOSE_ALERT = 'CLOSE_ALERT';

const notifySuccess = ({ commit }, payload) => {
    commit(NOTIFY_SUCCESS, payload);
};

const notifyError = ({ commit }, payload) => {
    commit(NOTIFY_ERROR, payload);
};

const closeAlert = ({ commit }, payload) => {
    commit(CLOSE_ALERT, payload);
};

export default {
    notifySuccess,
    notifyError,
    closeAlert,
};
