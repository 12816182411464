import { SET_CURRENT_PASS_OTP_PHONE, SET_CURRENT_TOKEN, SET_PASS_DATA, SET_ACCESS_TOKEN, CLEAR_CURRENT_TOKEN } from './actions';

export default {
    [SET_PASS_DATA]: (state, payload) => {
        state.passes[payload.passUri] = {
            token: payload.token,
            timezone: payload.timezone,
            propertyPhoneNumber: payload?.propertyPhoneNumber,
            propertyAddress: payload?.propertyAddress,
            apartmentCommunityName: payload?.apartmentCommunityName,
            passType: payload?.passType,
        };
    },

    [SET_CURRENT_TOKEN]: (state, payload) => {
        state.currentToken = payload;
    },

    [SET_ACCESS_TOKEN]: (state, payload) => {
        const passUriToUpdate = Object.keys(state.passes).find(uri => state.passes[uri].token === state.currentToken);

        if (passUriToUpdate) {
            state.passes[passUriToUpdate] = {
                ...state.passes[passUriToUpdate],
                accessToken: payload,
            };
        }
    },

    [SET_CURRENT_PASS_OTP_PHONE]: (state, payload) => {
        // eslint-disable-next-line no-unused-vars
        const currentPassUri = Object.entries(state.passes).find(([uri, data]) => data.token === state.currentToken)?.[0];
        if (currentPassUri) {
            state.passes[currentPassUri].otpPhone = payload;
        }
    },

    [CLEAR_CURRENT_TOKEN]: (state, payload) => {
        state.currentToken = undefined;
        state.passes[payload.passUri] = null;
    },
};
